<template>
  <div>
    <b-card class="card-custom" header-tag="header" footer-tag="footer">
      <StockInvoiceShow
        :isPageLoading.sync="isPageLoading"
        :firstLoader.sync="firstLoader"
        :selectedInvoiceId="selectedInvoiceId"
      ></StockInvoiceShow>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
      <v-overlay :value="!firstLoader && isLoadingSharedStore">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <template v-slot:footer>
        <BackButton></BackButton>
      </template>
    </b-card>
  </div>
</template>
<style scoped>
tr.previewTr th {
  font-size: 18px !important;
}
</style>
<script>
//General
import StockInvoiceShow from "@/modules/stock/components/actions/stockList/invoice/StockInvoiceShow.vue";
import { mapGetters } from "vuex";
//Shared Modules

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

export default {
  name: "StockInvoiceReview",
  mixins: [permissionMixin],
  data() {
    return {
      firstLoader: true,
      isPageLoading: true
    };
  },

  components: {
    StockInvoiceShow
  },
  computed: {
    ...mapGetters([
      "getItems",
      "isLoadingStock",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    selectedInvoiceId() {
      return this.$route.params.id;
    }
  },
  methods: {}
};
</script>
